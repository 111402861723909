import {
  fetchOneBox,
  executeTroubleShoot,
  fetchRelatedSearches,
  executeSearch,
  getTopDocuments,
} from "./apiCalls";

const TROUBLESHOOT_CONFIDENCE_THRESHOLD = 0.7;

let HOST: string;


switch (window.location.hostname) {
  case "access.redhat.com":
    HOST = "access.redhat.com";
    break;

  case "access.stage.redhat.com":
    HOST = "access.stage.redhat.com";
    break;

  case "access.qa.redhat.com":
    HOST = "access.qa.redhat.com";
    break;

  case "prod.foo.redhat.com":
    HOST = "access.redhat.com";
    break

  case "stage.foo.redhat.com":
    HOST = "access.stage.redhat.com";
    break;

  case "qa.foo.redhat.com":
    HOST = "access.qa.redhat.com";
    break

  default:
    HOST = "access.redhat.com";
}

const matchTopDocumentsWithResults = (topDocuments, results) => {
  //these will be the links I get back from documents. They don't necessarily come back with prod env - could be QA or stage
  //so we want to split the URL so we are only looking at the second half of it and not caring about the env
  // let links = ["https://access.redhat.com/articles/6932811", "https://access.redhat.com/solutions/6821131", "https://access.redhat.com/products/red-hat-openshift-container-platform", "https://access.redhat.com/products/red-hat-openshift-container-platform/#getstarted"]
  let splitLinks = topDocuments?.links?.map((link) => {
    return link
      .split(`https://access.redhat.com`)
      .filter((f) => f !== "")
      ?.toString();
  });

  let simplifiedResults: {}[];
  simplifiedResults = [];
  let tempURL;

  results?.map((result) => {
    let url = result?.view_uri;
    if (url?.includes("https://access.redhat.com")) {
      tempURL = result?.view_uri?.split(`https://access.redhat.com`);
    } else {
      tempURL = result?.view_uri?.split(`https://${HOST}`);
    }

    let titleToDisplay = result.allTitle
      ? result.allTitle
      : result.name
      ? result.name
      : result.title;
    simplifiedResults.push({
      title: titleToDisplay,
      linkToDisplay: `https://access.redhat.com${tempURL[1]}`,
      linkToMatch: `${tempURL[1]}`,
    });
  });

  interface PopularLink {
    text: string;
    link: string;
  }

  interface Listing {
    displayFeature: string;
    displayFeatureTitle: string;
    contentTitle: string;
    contentUrl: string;
    contentPositionRelative: string;
  }

  interface CardData {
    show: boolean;
    data: [];
    displayFeature: string;
    listings: Listing[];
  }

  interface Result {
    title: string;
    linkToDisplay: string;
    linkToMatch: string;
  }

  let cardData: CardData = {
    show: false,
    data: [],
    displayFeature: "",
    listings: [],
  };

  let popularLinks: Array<PopularLink> = [];
  let popularLinksData;

  // @ts-ignore
  simplifiedResults?.forEach((result: Result) => {
    if (splitLinks?.includes(result?.linkToMatch)) {
      popularLinks.push({
        text: result?.title,
        link: result?.linkToDisplay,
      });
    }
  });

  if (popularLinks.length === 0) {
    cardData.show = false;
  } else {
    popularLinksData = [
      { title: "Popular with similar users", ...popularLinks },
    ];
    cardData.show = true;
    cardData.data = popularLinksData;
    cardData.displayFeature = "Popular With Similar Users";
    cardData.listings = popularLinks?.map((popularLink: PopularLink, index) => {
      return {
        displayFeature: "Popular With Similar Users",
        displayFeatureTitle: "Popular With Similar Users",
        contentTitle: popularLink?.text,
        contentUrl: popularLink?.link,
        contentPositionRelative: `${index + 1}`,
        contentID: popularLink?.link,
      };
    });
  }

  return cardData;
};

export async function fetchAllData(query, settings) {
  const resultValues = [] as any[];
  const resultListings = [] as any[];
  const finalResultListings: any = [];
  let onSuccess;
  let results;
  let numResults;
  let suggestions;
  let documentKindFilters;
  let productFilters;
  let documentVersionFilters;
  let topDocuments;
  let facetInfo;
  let topDocMatches;

  const s = settings?.["executeSearchInput"];

  return Promise.all([
    executeTroubleShoot(query, HOST, TROUBLESHOOT_CONFIDENCE_THRESHOLD),
    fetchOneBox(query, HOST),
    executeSearch(
      s?.["value"],
      HOST,
      s?.["rows"],
      s?.["pageNumber"],
      s?.["contentTypes"],
      s?.["version"],
      s?.["sort"],
      s?.["products"],
      s?.["listingDriver"],
      s?.["newSearch"],
      s?.["productVersionFilterQuery"],
      s?.["facetVersionSearchQuery"],
      s?.["currentSort"],
    ),
    fetchRelatedSearches(query, HOST),
    getTopDocuments(query, HOST),
  ]).then((values) => {
    values.forEach((value) => {
      if (value?.onSuccess) {
        onSuccess = value?.onSuccess;
        results = value?.docResults;
        numResults = value?.response?.numFound;
        suggestions = value?.suggestionsArr || [];
        documentKindFilters = value?.facetsForContentTypes;
        productFilters = value?.facetsForProducts;
        documentVersionFilters = value?.facetsForDocumentVersions;
        facetInfo = value?.facetInfo;
      }

      if (value?.show) {
        if (!value.listings) {
          return;
        }

        value.listings.forEach((listing) => resultListings.push(listing));
        resultValues.push({
          displayFeature: value?.displayFeature,
          data: value?.data,
        });
      }

      if (value?.topDocuments) {
        topDocuments = value.topDocuments;
      }
    });

    if (topDocuments) {
      topDocMatches = matchTopDocumentsWithResults(topDocuments, results);
      topDocMatches?.listings?.forEach((listing) =>
        resultListings.push(listing),
      );
      resultValues.push({
        displayFeature: topDocMatches?.displayFeature,
        data: topDocMatches?.data,
      });
    }

    resultListings.forEach(
      (listing, index) => (listing.contentPosition = `${index + 1}`),
    );

    //setting relative position for Natural search results
    resultValues.forEach((result) => {
      if (result.displayFeature == "Natural") {
        for (let i = 0; i < result["data"].length; i++) {
          result.data[i].position = i + 1;
        }
      }
    });

    //check to see if no natural results are returned but recommended results are
    let recommendedWithoutNatural = false;
    let noNaturalResults = false;

    resultValues?.forEach((result) => {
      if (result.displayFeature === "Natural" && result.data.length === 0) {
        noNaturalResults = true;
      }
      if (
        noNaturalResults &&
        result.displayFeature === "People Also Searched" &&
        result.data.length > 0
      ) {
        recommendedWithoutNatural = true;
      }
    });

    //resultValuesAsList will contain each listing that we need to render that is different than our listings
    //arr for analytics -- ex: it has 1 OneBox value instead of 6
    //this arr will not be in order we are just getting the listings to render first
    let resultValuesAsList = [] as any;

    resultValues?.forEach((result) => {
      let currDisplayFeature = result.displayFeature;
      result["data"]?.forEach((res) => {
        res.displayFeature = currDisplayFeature;
        resultValuesAsList.push(res);
      });
    });

    //finalResultListings will be an array of the listings we pass into Content Listing Displayed EDDL event
    resultListings.forEach((listing, index) => {
      if (
        listing.displayFeature === "Natural" &&
        listing.contentPositionRelative === "5"
      ) {
        finalResultListings.push(listing);
        for (let i = index; i < resultListings.length; i++) {
          if (resultListings[i].displayFeature === "People Also Searched") {
            finalResultListings.push(resultListings[i]);
          }
        }
        //create conditon for when no natural search results but yes recommended
      } else if (
        listing.displayFeature === "People Also Searched" &&
        recommendedWithoutNatural
      ) {
        finalResultListings.push(listing);
      } else if (listing.displayFeature === "People Also Searched") {
        return;
      } else {
        finalResultListings.push(listing);
      }
    });

    //reset content position to account for People Also Searched Listings changing positioning when correct order is set
    let pos = 0;
    let oldFeature = "";

    let resultsForContentListingItemClicked = finalResultListings.map(
      (listing) => {
        let currFeature = listing.displayFeature;

        if (currFeature !== oldFeature && currFeature !== "Natural") {
          pos++;
          oldFeature = currFeature;
        }
        if (
          listing.displayFeature == currFeature &&
          listing.displayFeature !== "Natural"
        ) {
          listing.contentPosition = `${pos}`;
          return listing;
        } else if (listing.displayFeature === "Natural") {
          pos++;
          listing.contentPosition = `${pos}`;
          return listing;
        }
      },
    );

    //create copy of abov for loops to create an array with results in order
    /*
     * need an array of the features in order
     * We need
     * - One Troubleshoot
     * - One OneBox
     * - All natural results
     * - One People Also Searched
     * - One Popular
     */
    //this arr will be resultValuesAsList but in order
    //it will be passed to ResultCards.tsx as the listings to render IN ORDER
    let resultValuesToRender = [] as any;
    resultValuesAsList.forEach((result) => {
      if (result.displayFeature == "Natural" && result.position === 5) {
        resultValuesToRender.push(result);
        resultValuesAsList.forEach((r) => {
          if (r.displayFeature == "People Also Searched") {
            resultValuesToRender.push(r);
          }
        });
        //create conditon for when no natural search results but yes recommended
      } else if (
        result.displayFeature == "People Also Searched" &&
        recommendedWithoutNatural
      ) {
        resultValuesToRender.push(result);
      } else if (result.displayFeature == "People Also Searched") {
        return;
      } else {
        resultValuesToRender.push(result);
      }
      return resultValuesToRender;
    });

    //constuct string for filterList for Content Listing Displayed analytics event
    let filterListString = "";
    const filterUrl = new URLSearchParams(window.location.search);
    const productVersionFromUrl = filterUrl.get("documentation_version") || "";
    let contentTypeFilterFromUrl = filterUrl.get("documentKind") || "";
    let productFilterFromUrl = filterUrl.get("product") || "";

    if (contentTypeFilterFromUrl.length > 0) {
      contentTypeFilterFromUrl = contentTypeFilterFromUrl
        ?.split("&")
        ?.join("|documentKind~");
      filterListString += `documentKind~${contentTypeFilterFromUrl}`;
    }
    if (productFilterFromUrl.length > 0) {
      productFilterFromUrl = productFilterFromUrl.split("&").join("|product~");
      if (filterListString.length == 0) {
        filterListString += `product~${productFilterFromUrl}`;
      } else {
        filterListString += `|product~${productFilterFromUrl}`;
      }
    }

    if (productVersionFromUrl.length > 0) {
      let formattedVersions = productVersionFromUrl
        ?.split("&")
        .filter((f) => f !== "");
      let versionList = "";
      for (let i = 0; i < formattedVersions.length; i += 2) {
        let currVersions = formattedVersions[i + 1]?.split(",");
        for (let j = 0; j < currVersions?.length; j++) {
          if (currVersions[j] !== "") {
            versionList += `|documentation-version~${formattedVersions[i]}-${currVersions[j]}`;
          }
        }
      }
      filterListString += versionList;
    }

    let naturalCardsData = {
      results: results,
      numResults: numResults,
      suggestions: suggestions,
      documentKindFilters: documentKindFilters,
      productFilters: productFilters,
      documentVersionFilters: documentVersionFilters,
      //  topDocuments: topDocuments,
      facetInfo: facetInfo,
    };

    // add content key for resultsForContentListingItemClicked
    let finalResultListingsForEddl = resultsForContentListingItemClicked.map(
      (listing) => {
        return { content: listing };
      },
    );
    //@ts-ignore
    window.appEventData = window.appEventData || [];
    //@ts-ignore
    appEventData.push({
      event: "Content Listing Displayed",
      listingDisplayed: {
        filterList: filterListString,
        listing: finalResultListingsForEddl,
        listingDriver:
          s?.["listingDriver"] === "" ? "Onsite Search" : s?.["listingDriver"],
        listingType: "content",
        resultsCount: `${numResults}`,
        displayCount: `${resultValuesToRender?.length}`,
        sortOrder:
          s?.["currentSort"] === "" ? "Most Relevant" : s?.["currentSort"],
      },
    });

    return {
      resultValuesToRender,
      resultsForContentListingItemClicked,
      onSuccess,
      naturalCardsData,
    };
  });
}
