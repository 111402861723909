import {
  useRef,
  useEffect,
  useReducer,
  Dispatch,
  useState,
} from "react";
import { useLocation, Link } from "react-router-dom";
import "./components/input-form/input-form";
import "./components/ResultItem/result-item";
import "./components/SingleCardBorderedTop/single-card-bordered-top";
import "./components/DoubleCardBorderedTop/double-card-bordered-top";
import "./components/troubleshooting-card/troubleshooting-card";
import "./components/suggestion-state/suggestion-state";
import { Button, ExpandableSection, Pagination } from "@patternfly/react-core";
import "./App.css";
import "@patternfly/patternfly/patternfly.css";
import "@patternfly/pfelement/dist/pfelement.min.css";
import {
  SolrSortFields,
  trendingSearchesData,
  topPagesData
} from "../src/components/utils/Utils";
import DropdownSort from "./components/DropdownSort/DropdownSort";
import Filters from "./components/Filters/Filters";
import "@cpelements/cp-search-autocomplete";
import LoadingState from "./components/LoadingState/LoadingState";
import ErrorComponent from "./components/ErrorComponent/ErrorComponent";
import EmptyStateSuggestions from "./components/EmptyStateSuggestions/EmptyStateSuggestions";
import Suggestions from "./components/Suggestions/Suggestions";
import ResultCards from "./components/ResultCards/ResultCards";
import {
  getInitialSearchState,
  IState,
} from "./components/utils/IStateInterface";
import { fetchAllData } from "./components/utils/fetchAllData";
import { setUpABTest } from "./components/utils/setUpABTest";

// breakpoints obtained from
// https://github.com/RedHat-UX/red-hat-design-tokens/blob/1596d5d376f5a8e43cf3c0cbf815a7a2037c476f/tokens/breakpoints.yml
// @TODO: pull in breakpoints from npm
const BREAKPOINTS = [
  {
    experience: "Mobile",
    mediaQuery: "(max-width: 991px)",
  },
  {
    experience: "Tablet",
    mediaQuery: "(max-width: 1439px)",
  },
  {
    experience: "Desktop",
    mediaQuery: "(min-width: 1440px)",
  },
];

let isAuthenticated = false;

const reducer = (
  pState: IState,
  action: { type: any; value?: any },
): IState => {
  switch (action.type) {
    case "onNewSearch":
    case "onSearch":
    case "onUrlChange":
    case "onLocationChange":
    case "onPaginationChange":
      const merge = {
        ...pState,
        ...action.value,
      };

      const start = (merge.pageNumber - 1) * merge.rows;
      merge.start = start;
      return merge;
    case "onFilterChange":
      const merged = {
        ...pState,
        ...action.value,
      };

      if (action?.value?.activeProductFilters) {
        //this logic happens when onFilterChange is called for the product filter
        if (action.value.activeProductFilters.length == 0) {
          //if no product filters are selected, all versions are set to empty arrays
          merged.activeVersionFilters = [];
          merged.activeExcludedVersionFilters = [];
        } else {
          let updatedActiveVersion = {};
          //find out exactly but I think this is setting the versions to [] if a certain product is deselected -- just haven't figured out how to remove
          //from URL yet
          for (const [key, value] of Object.entries(
            pState.activeVersionFilters,
          )) {
            if (!action?.value?.activeProductFilters.includes(`"${key}"`)) {
              updatedActiveVersion = { [key]: [] };
            }
          }
        }
      }
      //the following logic is when onFilterChange is dispatched for facetVersionSelect
      if (action?.value?.activeVersionFilters) {
        //@ts-ignore
        if (pState.activeVersionFilters == 0) {
          //TODO - WANTED to make this array so I can spread them on excpet then the obj won't update
          // merged.activeVersionFilters = [action.value.activeVersionFilters]
        } else {
          if (
            //@ts-ignore
            Object.values(action.value.activeVersionFilters)[0]?.length >= 1
          ) {
          }
          //if the current version's product already exists in curr product filters, spread this curr version filters onto
          //the previous state AT THAT PRODUCT

          //next, we can spread both arrays an combine them. the curr version object needs to be an array so that we can spread

          let currVersionProduct = Object.keys(
            action.value.activeVersionFilters,
          );
          let curr = `'"${currVersionProduct}"'`;

          //what is this? fix and figure out what else spreading arr is and when I'll get there. maybe if I remove a product if array is empty for excluded filters
          if (pState.activeProductFilters.indexOf(curr) == -1) {
            //TODO - how can I remove a product if they have no versions selected?
            //right now I'm doing it in the execute search function for fq params. getting rid of all products with versions that are empty strings
            // console.log("pState?.activeVersionFilters", pState?.activeVersionFilters)
            // console.log("action.value.activeVersionFilters", action.value.activeVersionFilters)
            // console.log("merged", { ...pState?.activeVersionFilters, ...action.value.activeVersionFilters })
            merged.activeVersionFilters = {
              ...pState?.activeVersionFilters,
              ...action.value.activeVersionFilters,
            };

            merged.activeExcludedVersionFilters = {
              ...pState.activeExcludedVersionFilters,
              ...action.value.activeExcludedVersionFilters,
            };
          } else {
            console.log("spreading array");
          }
        }
      }
      return merged;
    case "onSuccess":
    case "onError":
      return {
        ...pState,
        ...action.value,
      };
    case "onReset":
      return getInitialSearchState();
    case "onSortChange":
      return {
        ...pState,
        ...action.value,
      };
    default: {
      return pState;
    }
  }
};

// analytics team wants to delay the execution of a search until
// we've determined if the user is authenticated or not
const userDetected = async () => {
  const promise = new Promise<void>((resolve, reject) => {
    // @ts-ignore

    window?.portal?.primer?.session.onInit(async () => {
      return resolve();
    });
  });

  return promise;
};

function App(props) {
  const [state, dispatch]: [IState, Dispatch<any>] = useReducer(
    reducer,
    getInitialSearchState(),
  );
  const [resultCardData, setResultCardData] = useState([] as any);
  const [resultCardAllListings, setResultCardAllListings] = useState([] as any);
  const [isExpanded, setIsExpanded] = useState(true);
  const inputFormRef: any = useRef(null);
  const location = useLocation();
  const searchParams = location.search;
  const [isProductABTest, setIsProductABTest] = useState(false);
  const [productABTestVariant, setProductABTestVariant] = useState('');



  useEffect(() => {
    //@ts-ignore
    document.addEventListener("onebox-link-click", (e: any) => {
      //@ts-ignore
      window.appEventData = window.appEventData || [];

      //@ts-ignore
      appEventData.push({
        event: "Content Listing Item Clicked",
        linkInfo: {
          displayPosition: ``,
          category: "Onebox",
          text: `${e.link}`,
          href: `${
            e.target.data.links.find((link) => link.text === e.link).link
          }`,
          linkTypeName: "search result click",
          targetHost: `access.redhat.com`,
        },
        listingItemClicked: {
          listingType: "content",
          listing: [
            {
              content: {
                displayFeature: `Onebox`,
                displayFeatureTitle: `Onebox: ${e.target.data.title}`,
                contentTitle: `${e.link}`,
                contentUrl: `${
                  e.target.data.links.find((link) => link.text === e.link).link
                }`,
                contentID: `${
                  e.target.data.links.find((link) => link.text === e.link).link
                }`,
                contentPositionRelative: `${
                  e.target.data.links.findIndex(
                    (link) => link.text === e.link,
                  ) + 1
                }`,
                contentPosition: ``,
              },
            },
          ],
        },
      });
    });
  }, []);

  let oneBoxTestResultCard;
  resultCardData.map((data) => {
    if (data.displayFeature == "OneBox") {
      oneBoxTestResultCard = (
        <cp-onebox data={JSON.stringify(data)}></cp-onebox>
      );
    }
  });

  const path = useLocation().pathname;

  const activeFilters =
    state.activeContentTypeFilter.length > 0 ||
    state.activeProductFilters.length > 0;

  const preSearch = async () => {
    await userDetected();

    dispatch({
      type: "onSearch",
      value: {
        loading: true,
      },
    });
  };

  const executeSearchHelper = async (success, cardData) => {
    const url = new URLSearchParams(window.location.search);
    const q = url.get("q")?.trim();
    await userDetected();

    dispatch({
      type: "onSearch",
      value: {
        loading: true,
      },
    });

    if (success) {
      dispatch({
        type: "onSuccess",
        value: {
          results: cardData?.results,
          numResults: cardData?.numResults,
          suggestions: cardData?.suggestions,
          loading: false,
          init: false,
          error: false,
          documentKindFilters: cardData?.documentKindFilters,
          productFilters: cardData?.productFilters,
          documentVersionFilters: cardData?.documentVersionFilters,
          topDocuments: cardData?.topDocuments,
        },
      });
    }

    if (!success) {
      dispatch({
        type: "onError",
        value: {
          rows: 10,
          pageNumber: 1,
          startResultIndex: 0,
          loading: false,
          init: true,
          results: [],
          numResults: 0,
          suggestions: [],
          error: true,
          documentKindFilters: [],
          activeContentTypeFilter: [],
          topDocuments: [],
        },
      });
      //ONSITE SEARCH FAILED
      //@ts-ignore
      window.appEventData = window.appEventData || [];
      //@ts-ignore
      appEventData.push({
        event: "Onsite Search Failed",
        onsiteSearch: {
          keyword: {
            searchTerm: q,
            searchType: "search_page",
          },
        },
      });

      //ERROR MESSAGE PRESENTED
      //@ts-ignore
      appEventData.push({
        event: "Error Message Presented",
        error: {
          errorCode: "The search service is experiencing a problem",
          errorType: "Form",
        },
      });
    }
  };

  const abTesting = async () => {
    if(isProductABTest) return;

    const productABTestData = setUpABTest("product-test-ready"); // this line is the target html attribute for the specific test
    setIsProductABTest(productABTestData.active); // boolean
    setProductABTestVariant(productABTestData.variant); // string,
  };

  useEffect(() => {
    
    let searchTestEvent = new CustomEvent("AB1173464-ready");
    // Begin Custom code for CPSEARCH-8117
    // @ts-ignore
    if(window?.adobe?.target) {
      //@ts-ignore
      window.AB1173464 = 'loading';
      //@ts-ignore
      adobe.target.getOffer({
        "mbox": "cp-search-results",
        "params": {},
        "success": function(offer) {
          //@ts-ignore
          adobe.target.applyOffer({
            "mbox": "cp-search-results",
            "offer": offer
            });
            //@ts-ignore
            window.AB1173464 = 'complete';
            document.dispatchEvent (searchTestEvent);
        },
        "error": function(status, error) {
          console.log('Error', status, error);
        }
      });
    } else {
      // @daniel --> render default page, user not in test
    }
    //@ts-ignore
    if (window.AB1173464 === 'loading') {
      let ab117364_page_rendered = false;
      document.addEventListener("AB1173464-ready", () => {
        ab117364_page_rendered = true;
        console.log("DOM ready for check!")
        // @daniel --> check for "product-test-ready"/A or B attribute & render page
        setTimeout(() => {
          abTesting();
        }, 50)
        
      });
      setTimeout(() => {
        if (!ab117364_page_rendered) {
          // @daniel --> issue with Target loading, render default page
        }
      }, 1000)
      //@ts-ignore
    } else if (window.AB1173464 === 'complete') {
      // @daniel --> check for "product-test-ready"/A or B attribute & render page
      abTesting(); 
    }
    // End Custom code for CPSEARCH-8117

    const urlForEvents = new URL(window.location.href);
    const searchParams = new URLSearchParams(window.location.search);

    let updatedUrl;
    const url = window.location.href;
    const navSearch = url.includes("#/") ? true : false;

    if (navSearch) {
      updatedUrl = url.replace("#/", "");
      window.history.pushState({}, "", updatedUrl);
    }

    //@ts-ignore
    window.appEventData = window.appEventData || [];
    //@ts-ignore
    appEventData.push({
      event: "Page dataObject Set",
      page: {
        dataObject: "appEventData",
      },
    });

    //@ts-ignore
    window.appEventData = window.appEventData || [];

    //@ts-ignore
    appEventData.push({
      event: "Page Load Started",
      page: {
        queryParameters: {
          q: searchParams.get("q") || urlForEvents?.hash?.split("q=")?.[1],
        },
        pageCategory: "search",
        pageName: "cp|search",
        siteName: "cp",
        pageTitle: "Customer Portal Search",
        pageType: "",
        pageSubType: "",
        pageStatus: "",
        siteExperience: (() => {
          for (let i = 0; i < BREAKPOINTS.length; i++) {
            if (window.matchMedia(BREAKPOINTS[i].mediaQuery).matches) {
              return BREAKPOINTS[i].experience;
            }
          }
          return "undefined";
        })(),
        siteLanguage: "en",
        subsection: "",
        subsection2: "",
        subsection3: "",
        cms: "",
        analyticsTitle: "",
        blogAuthor: "",
        contentID: "",
        contentType: "",
        destinationURL: "",
        gated: "",
        taxonomyMetaHreflang: [],
        taxonomyRegion: [],
        taxonomyBlogPostCategory: [],
        taxonomyBusinessChallenge: [],
        taxonomyProduct: [],
        taxonomyProductLine: [],
        taxonomySolution: [],
        taxonomyTopic: [],
        taxonomyAuthor: [],
        taxonomyStage: [],
        dataObject: "appEventData",
      },
    });

    // user detected
    //@ts-ignore
    window?.portal?.primer?.session.onInit(async (session) => {
      if (!session.isAuthenticated()) {
        //@ts-ignore
        window.appEventData = window.appEventData || [];

        //@ts-ignore
        window.appEventData.push({
          event: "User Detected",
          user: {
            ebsAccountNumber: "",
            userID: "",
            lastLoginDate: "",
            loggedIn: "false",
            organizationID: "",
            socialAccountsLinked: "",
            ssoClientID: "",
            hashedEmail: "",
          },
        });

        // page load completed
        //@ts-ignore
        appEventData.push({
          event: "Page Load Completed",
        });
      } else {
        isAuthenticated = true;
        const user = session.getUserInfo();

        //@ts-ignore
        window.appEventData = window.appEventData || [];

        //@ts-ignore
        window.appEventData.push({
          event: "User Detected",
          user: {
            ebsAccountNumber: user.account_number,
            userID: user.user_id,
            lastLoginDate: "",
            loggedIn: "true",
            organizationID: user.account_id,
            socialAccountsLinked: "",
            ssoClientID: "",
            hashedEmail: "",
          },
        });

        // page load completed
        //@ts-ignore
        appEventData.push({
          event: "Page Load Completed",
        });
      }
      // this is old stuff that will be removed eventually. dpal super old code
      //@ts-ignore
      window?.portal?.primer?.pageview?.send();
    });
  }, []);

  useEffect(() => {
    abTesting();
  }, [searchParams]);

  useEffect(() => {
    inputFormRef.current.addEventListener(
      "input-form:submit",
      async (event) => {
        const url = new URL(window.location.href);
        url.searchParams.set("q", event.detail.inputValue);
        url.searchParams.set("p", "1");
        url.searchParams.set("rows", "10");
        url.searchParams.get("documentKind") &&
          url.searchParams.delete("documentKind");
        url.searchParams.get("sort") && url.searchParams.delete("sort");
        url.searchParams.get("product") && url.searchParams.delete("product");
        url.searchParams.get("documentation_version") &&
          url.searchParams.delete("documentation_version");

        let preselectedFilters = props[path] ? props[path] : [];

        const contentTypeUrlFilterParams = preselectedFilters?.join("&");
        if (contentTypeUrlFilterParams?.length) {
          url.searchParams.set(
            "documentKind",
            contentTypeUrlFilterParams.replace(/['"]+/g, ""),
          );
        }
        window.history.pushState({}, "", url.href);

        let executeSearchInput = {
          value: event.detail.inputValue,
          rows: 10,
          pageNumber: 1,
          contentTypes: preselectedFilters,
          version: "",
          sort: "",
          products: "",
          listingDriver: "",
          newSearch: "newSearch",
          productVersionFilterQuery: {},
          facetVersionSearchQuery: {},
          currentSort: "Most Relevant",
        };

        await preSearch();

        fetchAllData(event.detail.inputValue, { executeSearchInput }).then(
          (response) => {
            setResultCardData(response["resultValuesToRender"]);
            setResultCardAllListings(
              response["resultsForContentListingItemClicked"],
            );
            executeSearchHelper(
              response["onSuccess"],
              response["naturalCardsData"],
            );
            dispatch({
              type: "onNewSearch",
              value: {
                inputValue: event.detail.inputValue,
                rows: 10,
                pageNumber: 1,
                startResultIndex: 0,
                activeContentTypeFilter: preselectedFilters,
                sort: "",
                activeProductFilters: [],
                activeVersionFilters: [],
                activeExcludedVersionFilters: [],
              },
            });
            
          },
        );
      },
    );
  }, []);

  useEffect(() => {
    async function onLocationChange() {
      const url = new URLSearchParams(window.location.search);
      const q = url.get("q")?.trim();
      const numRows = url.get("rows") || "10";
      const page = url.get("p") || "1";
      let docKind = url.get("documentKind")?.split("&") || "";
      //@ts-ignore
      docKind = (docKind.length && docKind.map((d) => `"${d}"`)) || [];
      let docVersion = url.get("documentation_version")?.split("&") || "";

      let activeVersionFilterFromUrl = {};
      //@ts-ignore
      if (docVersion.length > 1) {
        //@ts-ignore
        docVersion = docVersion?.filter((d) => d !== "");
        for (var i = 0; i < docVersion.length; i += 2) {
          //remove '' from arr when retreiving versions from URL -- see if I can add to URL with diff chars to avoid this when spliting on ","
          let versionsArr = docVersion[i + 1]?.split(",");
          versionsArr = versionsArr?.filter((item) => item != "");
          //@ts-ignore
          activeVersionFilterFromUrl = {
            ...activeVersionFilterFromUrl,
            ...{ [docVersion[i]]: versionsArr },
          };
        }
      }

      let contentTypeFilters = props[path] ? props[path] : docKind;
      const urlForDocKind = new URL(window.location.href);
      const contentTypeUrlFilterParams = props[path]?.join("&");
      if (contentTypeUrlFilterParams?.length) {
        urlForDocKind.searchParams.set(
          "documentKind",
          contentTypeUrlFilterParams.replace(/['"]+/g, ""),
        );
        window.history.pushState({}, "", urlForDocKind.href);
      }

      const sortTerm = url.get("sort") || "";
      let productTerm = url.get("product")?.split("&") || "";
      productTerm =
        //@ts-ignore
        (productTerm.length && productTerm.map((p) => `"${p}"`)) || [];

      if (!q) {
        dispatch({
          type: "onReset",
        });
        return;
      }


      let allVersions = url.get("documentation_version")?.split("&") || [];
      let product = url.get("product") || "";
      let versionFiltersSelected = url.get("documentation_version")?.split("&") || [];
      let filteredExcludedVersions = [];


      let fqProductVersion;
      //if there is only 1 version for a product, we don't want to add it to our fetch call with a fq because the excluded filters would be an empty string
      if (versionFiltersSelected.length == 0 || allVersions.length == 1) {
        fqProductVersion = { [product]: "" };
      } else {
        //construct the facet query for our fetch call - we want to query on product minus all versions NOT selected.
        //BECAUSE not all documents have a version.  Even if they have the right product, they would not get returned if we query on product & version
        //using filteredExcludedVersions so the version counts are removed (added for facet AB test)
        fqProductVersion = {
          [product]: `standard_product:"${product}" AND documentation_version:("${allVersions.join(
            '" OR "',
          )}")`,
        };
      }

      let executeSearchInput = {
        value: q,
        rows: numRows,
        pageNumber: page,
        contentTypes: contentTypeFilters,
        version: activeVersionFilterFromUrl,
        sort: sortTerm,
        products: productTerm,
        listingDriver: "",
        newSearch: "",
        productVersionFilterQuery: state.activeExcludedVersionFilters,
        facetVersionSearchQuery: fqProductVersion,
        currentSort: "",
      };

      await preSearch();

      fetchAllData(q, { executeSearchInput }).then((response) => {
        setResultCardData(response["resultValuesToRender"]);
        setResultCardAllListings(
          response["resultsForContentListingItemClicked"],
        );
        executeSearchHelper(
          response["onSuccess"],
          response["naturalCardsData"],
        );

        let documentVersions =
          response["naturalCardsData"]?.facetInfo?.facet_pivot?.[
            "standard_product,documentation_version"
          ];
        let excludedFiltersFqs = {};
        let fqProductVersion;
        if (documentVersions?.length > 0) {
          for (var i = 0; i < documentVersions?.length; i += 1) {
            let versionsObj = {};
            let activeVersionsArr: string[] = [];
            for (const [key, value] of Object.entries(
              activeVersionFilterFromUrl,
            )) {
              activeVersionsArr.push(key);
            }
    
            response["naturalCardsData"]?.documentVersionFilters?.forEach((obj) => {
              Object.keys(obj).forEach((key) => {
                if (activeVersionsArr.includes(key)) {
                  versionsObj = { ...obj, ...versionsObj };
                }
              });
            });

            //loops through activeVersionFilterFromUrl and versions to get the excluded filters
            for (const [key, value] of Object.entries(versionsObj)) {
              let currFq;
              let arrToCompareAgainst = activeVersionFilterFromUrl[key];
              
              //@ts-ignore
              let newArr = value?.filter(
                (v) => !arrToCompareAgainst.includes(v),
              );
              if (newArr.length == 0 || newArr.length == 1) {
                fqProductVersion = { [key]: "" };
              } else {
                // take newArr and remove count variable, then join with " OR " to create a string
                let arrWithoutCount = newArr.map((v) => v[0]);
                fqProductVersion = {
                  [key]: `standard_product:"${key}" AND documentation_version:("${arrWithoutCount.join(
                    '" OR "',
                  )}")`,
                };
              }
              excludedFiltersFqs = {
                ...excludedFiltersFqs,
                ...fqProductVersion,
              };
            }
          }
        }

        //@ts-ignore
        dispatch({
          type: "onNewSearch",
          value: {
            inputValue: q.trim(),
            pageNumber: parseInt(page, 10),
            rows: parseInt(numRows, 10),
            startResultIndex: (parseInt(page, 10) - 1) * parseInt(numRows, 10),
            activeVersionFilters: activeVersionFilterFromUrl,
            activeExcludedVersionFilters: excludedFiltersFqs,
            facetVersionSearchQuery: excludedFiltersFqs,
            activeContentTypeFilter: contentTypeFilters,
            sort: sortTerm,
            activeProductFilters: productTerm,
          },
        });
      });
    }

    onLocationChange();
  }, [location]);

  useEffect(() => {
    if (!state.inputValue) {
      inputFormRef.current.shadowRoot
        .querySelector("cp-search-autocomplete")
        ._input.focus();
    } else {
      inputFormRef.current.shadowRoot.querySelector(
        "cp-search-autocomplete",
      )._input.value = state.inputValue;
    }
  }, [state.inputValue]);

  let suggestionStateData = {
    suggestions: state.suggestions,
    searchTerm: state.inputValue,
  };

  const CURRENT_SORT =
    SolrSortFields.sortFields?.find(
      (sortField) => sortField?.queryParam === state.sort,
    )?.select || "Most Relevant";

  const onPaginationChange = async (rows: number, pageNumber: number) => {
    setTimeout(
      () =>
        document.querySelector("#top-wrapper")?.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        }),
      50,
    );

    let start;

    if (rows !== state.rows) {
      start = 0;
      pageNumber = 1;
    } else {
      start = (pageNumber - 1) * rows;
    }

    const url = new URL(window.location.href);
    url.searchParams.set("rows", rows.toString());
    url.searchParams.set("p", pageNumber.toString());

    window.history.pushState({}, "", url.href);
    //send in content type if exists
    let docKind = state.activeContentTypeFilter || "";
    //send in facets if they exist
    const docVersion = state.activeVersionFilters || "";
    const sortTerm = state.sort || "";
    const productTerm = state.activeProductFilters || "";
    const q = url.searchParams.get("q");

    let executeSearchInput = {
      value: q,
      rows: rows,
      pageNumber: pageNumber,
      contentTypes: docKind,
      version: docVersion,
      sort: sortTerm,
      products: productTerm,
      listingDriver: "",
      newSearch: "Refinement",
      productVersionFilterQuery: state.activeProductFilters,
      facetVersionSearchQuery: state.activeExcludedVersionFilters,
      currentSort: "",
    };

    await preSearch();

    fetchAllData(q, { executeSearchInput }).then((response) => {
      setResultCardData(response["resultValuesToRender"]);
      setResultCardAllListings(response["resultsForContentListingItemClicked"]);
      executeSearchHelper(response["onSuccess"], response["naturalCardsData"]);

      dispatch({
        type: "onPaginationChange",
        value: {
          inputValue: url.searchParams.get("q")?.trim(),
          rows,
          pageNumber,
          startResultIndex: start,
        },
      });
    });
  };

  const facetSearch = async (productFiltersSelected, product) => {
    const url = new URL(window.location.href);
    url.searchParams.set("p", "1");
    url.searchParams.set("rows", "10");
    const productFilterParams = productFiltersSelected.join("&");
    let updatedActiveVersionFilters = {};
    let updatedActiveExcludedVersionFilters = {};

    if (productFilterParams.length) {
      url.searchParams.set(
        "product",
        productFilterParams.replace(/['"]+/g, ""),
      );
      window.history.pushState({}, "", url.href);
      //if we delete a product, but there are still more products selected, check if there are versions associated
      //with that product selected and remove them from the url

      let documentationVersionsFromUrl =
        url.searchParams
          .get("documentation_version")
          ?.split("&")
          ?.filter((d) => d !== "") || [];

      let updatedVersionsForUrl = "";
      if (
        !productFiltersSelected.includes(product) &&
        documentationVersionsFromUrl.length > 0
      ) {
        documentationVersionsFromUrl = documentationVersionsFromUrl?.filter(
          (d) => d !== "",
        );
        const productVerionsToRemove = [];
        documentationVersionsFromUrl.forEach((d, index) => {
          if (product == d) {
            //@ts-ignore
            productVerionsToRemove.push(documentationVersionsFromUrl[index]);
            productVerionsToRemove.push(
              //@ts-ignore
              documentationVersionsFromUrl[index + 1],
            );
          }
        });
        const updatedDocVersionsForUrl = documentationVersionsFromUrl.filter(
          //@ts-ignore
          (v) => !productVerionsToRemove.includes(v),
        );
        let newStr = updatedDocVersionsForUrl.join("&");
        updatedVersionsForUrl += newStr;
        if (updatedVersionsForUrl.length == 0) {
          url.searchParams.delete("documentation_version");
        } else {
          url.searchParams.set("documentation_version", updatedVersionsForUrl);
        }
        window.history.pushState({}, "", url.href);
        let currProdVersionObj = { [product]: 0 };
        updatedActiveVersionFilters = {
          ...state.activeVersionFilters,
          ...currProdVersionObj,
        };
        let currExcludedProductVersion = { [product]: "" };
        updatedActiveExcludedVersionFilters = {
          ...state.activeExcludedVersionFilters,
          ...currExcludedProductVersion,
        };
        //now we have to update activeExcludedVersionFilters
        //we want to take the current state.activeExcludedVersionFilters and spread the product we are deleted with an empty string
      }
    } else {
      url.searchParams.delete("product");
      //IF we delete product, we also want to delete documentation_version because no products means no versions selected
      url.searchParams.get("documentation_version") &&
        url.searchParams.delete("documentation_version");
      window.history.pushState({}, "", url.href);
      //no products are selected so we need to send empty documentation_version values to our reducer so a correct search query is executed
      let currProdVersionObj = { [product]: 0 };
      updatedActiveVersionFilters = {
        ...state.activeVersionFilters,
        ...currProdVersionObj,
      };
      let currExcludedProductVersion = { [product]: "" };
      updatedActiveExcludedVersionFilters = {
        ...state.activeExcludedVersionFilters,
        ...currExcludedProductVersion,
      };
    }

    const filterUrl = new URLSearchParams(window.location.search);
    // let filterFromUrl = filterUrl.get('documentKind') || "";
    let contentTypeFilterFromUrl = filterUrl.get("documentKind") || "";
    let productFilterFromUrl = filterUrl.get("product") || "";
    const productVersionFromUrl = filterUrl.get("documentation_version") || "";
    let filterListType = "";
    if (contentTypeFilterFromUrl.length > 0) {
      contentTypeFilterFromUrl = contentTypeFilterFromUrl
        ?.split("&")
        ?.join("|documentKind~");
      filterListType += `documentKind~${contentTypeFilterFromUrl}`;
    }
    if (productFilterFromUrl.length > 0) {
      productFilterFromUrl = productFilterFromUrl.split("&").join("|product~");
      if (filterListType.length == 0) {
        filterListType += `product~${productFilterFromUrl}`;
      } else {
        filterListType += `|product~${productFilterFromUrl}`;
      }
    }
    if (productVersionFromUrl.length > 0) {
      let formattedVersions = productVersionFromUrl
        ?.split("&")
        .filter((f) => f !== "");
      let versionList = "";
      for (let i = 0; i < formattedVersions.length; i += 2) {
        let currProd = formattedVersions[i];
        let currVersions = formattedVersions[i + 1]?.split(",");
        for (let j = 0; j < currVersions?.length; j++) {
          if (currVersions[j] !== "") {
            versionList += `|documentation-version~${formattedVersions[i]}-${currVersions[j]}`;
          }
        }
      }
      filterListType += versionList;
    }
    if (productFiltersSelected.includes(`"${product}"`)) {
      //@ts-ignore
      window.appEventData = window.appEventData || [];
      //@ts-ignore
      appEventData.push({
        event: "Listing Filter Added",
        listingDisplayed: {
          filterList: filterListType,
        },
        listingRefined: {
          listingType: "content",
        },
      });
    } else {
      //@ts-ignore
      window.appEventData = window.appEventData || [];
      //@ts-ignore
      appEventData.push({
        event: "Listing Filter Removed",
        listingDisplayed: {
          filterList: filterListType,
        },
        listingRefined: {
          listingType: "content",
        },
      });
    }

    let q = url.searchParams.get("q");
    let activeVerions =
      Object.keys(updatedActiveVersionFilters).length !== 0
        ? updatedActiveVersionFilters
        : state.activeVersionFilters;

    let executeSearchInput = {
      value: q,
      rows: 10,
      pageNumber: 1,
      contentTypes: state.activeContentTypeFilter,
      version: activeVerions,
      sort: state.sort,
      products: productFiltersSelected,
      listingDriver: "",
      newSearch: "Refinement",
      productVersionFilterQuery: "",
      facetVersionSearchQuery: state.activeExcludedVersionFilters,
      currentSort: "",
    };

    await preSearch();

    fetchAllData(q, { executeSearchInput }).then((response) => {
      setResultCardData(response["resultValuesToRender"]);
      setResultCardAllListings(response["resultsForContentListingItemClicked"]);
      executeSearchHelper(response["onSuccess"], response["naturalCardsData"]);

      dispatch({
        type: "onFilterChange",
        value: {
          rows: 10,
          pageNumber: 1,
          startResultIndex: 0,
          activeProductFilters: productFiltersSelected,
          activeVersionFilters: activeVerions,
          activeExcludedVersionFilters: updatedActiveExcludedVersionFilters,
        },
      });
    });
  };

  const facetVersionSearch = async (
    versionFiltersSelected,
    product,
    allVersions,
    selection,
  ) => {
    let currProductVersionFilter = { [product]: versionFiltersSelected };
    //remove version count from all versions so we are just looking at actual versions selected
    const excludedVersions = allVersions?.filter(
      (version) => !versionFiltersSelected.includes(version?.[0]),
    );
    let filteredExcludedVersions = [];
    for (let i = 0; i < excludedVersions?.length; i++) {
      //@ts-ignore
      filteredExcludedVersions.push(excludedVersions[i][0]);
    }

    const url = new URL(window.location.href);
    url.searchParams.set("p", "1");
    url.searchParams.set("rows", "10");
    //see if state has active version filters already
    //when we remove all version filters from state.activeVersionFilters, the product still exists as an obj just with no value
    //so, we are iterating through state.activeVersionFilters and adding an active product/version pair if the value of versions is > 0
    let productVersionsAlreadySelected = [];

    for (const [key, value] of Object.entries(state.activeVersionFilters)) {
      if (product !== key) {
        let versionsJoined;
        if (value.length == 0 || value == 0) {
          continue;
        }
        if (value.length > 0) {
          versionsJoined = value.join(",");
        }
        productVersionsAlreadySelected.push(
          //@ts-ignore
          `&${key}&${versionsJoined?.replace(/['"]+/g, "")}`,
        );
      }
    }
    //if no filters already selected but there are selections for this curr prod, set them to just what's in this filter
    if (
      productVersionsAlreadySelected.length == 0 &&
      versionFiltersSelected.length > 0
    ) {
      const versionFilterParams = versionFiltersSelected.join(",");
      url.searchParams.set(
        "documentation_version",
        `&${product}&${versionFilterParams?.replace(/['"]+/g, ",")}`,
      );

      window.history.pushState({}, "", url.href);

      //if state has active version filters, set documentation version to those plus the ones in this current filter
    } else if (
      productVersionsAlreadySelected.length > 0 &&
      versionFiltersSelected.length > 0
    ) {
      const versionFilterParams = versionFiltersSelected.join(",");

      const versionsAlreadySelected = productVersionsAlreadySelected.toString();

      url.searchParams.set(
        "documentation_version",
        `${product}&${versionFilterParams.replace(
          /['"]+/g,
          "",
        )},${versionsAlreadySelected}`,
      );
      window.history.pushState({}, "", url.href);
      // console.log("2nd url.searchParams.getdocumentation_version", url.searchParams.get("documentation_version"))
      //if state has active version filters but none are being added here (ex- all versions for a given product are deselected)
    } else if (
      productVersionsAlreadySelected.length > 0 &&
      versionFiltersSelected.length == 0
    ) {
      const versionsAlreadySelected = productVersionsAlreadySelected.join(",");
      // console.log("versionsAlreadySelected in 3rd", versionsAlreadySelected)
      url.searchParams.set(
        "documentation_version",
        `${versionsAlreadySelected.replace(/['"]+/g, ",")}`,
      );
      window.history.pushState({}, "", url.href);
    } else {
      //else, if no filters in state and none in this function, delete them from url
      url.searchParams.delete("documentation_version");
      window.history.pushState({}, "", url.href);
    }

    let fqProductVersion;
    //if there is only 1 version for a product, we don't want to add it to our fetch call with a fq because the excluded filters would be an empty string
    if (versionFiltersSelected.length == 0 || allVersions.length == 1) {
      fqProductVersion = { [product]: "" };
    } else {
      //construct the facet query for our fetch call - we want to query on product minus all versions NOT selected.
      //BECAUSE not all documents have a version.  Even if they have the right product, they would not get returned if we query on product & version
      //using filteredExcludedVersions so the version counts are removed (added for facet AB test)
      fqProductVersion = {
        [product]: `standard_product:"${product}" AND documentation_version:("${filteredExcludedVersions.join(
          '" OR "',
        )}")`,
      };
    }

    //check to see if version is added or removed
    const filterUrl = new URLSearchParams(window.location.search);
    let contentTypeFilterFromUrl = filterUrl.get("documentKind") || "";
    let productFilterFromUrl = filterUrl.get("product") || "";
    const productVersionFromUrl = filterUrl.get("documentation_version") || "";
    let filterListType = "";
    if (contentTypeFilterFromUrl.length > 0) {
      contentTypeFilterFromUrl = contentTypeFilterFromUrl
        ?.split("&")
        ?.join("|documentKind~");
      filterListType += `documentKind~${contentTypeFilterFromUrl}`;
    }
    if (productFilterFromUrl.length > 0) {
      productFilterFromUrl = productFilterFromUrl.split("&").join("|product~");
      if (filterListType.length == 0) {
        filterListType += `product~${productFilterFromUrl}`;
      } else {
        filterListType += `|product~${productFilterFromUrl}`;
      }
    }

    if (productVersionFromUrl.length > 0) {
      let formattedVersions = productVersionFromUrl
        ?.split("&")
        .filter((f) => f !== "");
      let versionList = "";
      for (let i = 0; i < formattedVersions.length; i += 2) {
        let currProd = formattedVersions[i];
        let currVersions = formattedVersions[i + 1]?.split(",");
        for (let j = 0; j < currVersions?.length; j++) {
          if (currVersions[j] !== "") {
            versionList += `|documentation-version~${formattedVersions[i]}-${currVersions[j]}`;
          }
        }
      }
      filterListType += versionList;
    }

    let filterAdded;
    if (versionFiltersSelected.includes(selection)) {
      filterAdded = true;
    } else {
      filterAdded = false;
    }
    if (filterAdded) {
      //@ts-ignore
      window.appEventData = window.appEventData || [];
      //@ts-ignore
      appEventData.push({
        event: "Listing Filter Added",
        listingDisplayed: {
          filterList: filterListType,
        },
        listingRefined: {
          listingType: "content",
        },
      });
    } else {
      //@ts-ignore
      window.appEventData = window.appEventData || [];
      //@ts-ignore
      appEventData.push({
        event: "Listing Filter Removed",
        listingDisplayed: {
          filterList: filterListType,
        },
        listingRefined: {
          listingType: "content",
        },
      });
    }

    let q = url.searchParams.get("q");

    let executeSearchInput = {
      value: q,
      rows: 10,
      pageNumber: 1,
      contentTypes: state.activeContentTypeFilter,
      version: currProductVersionFilter,
      sort: state.sort,
      products: state.activeProductFilters,
      listingDriver: "Refinement",
      newSearch: "",
      productVersionFilterQuery: state.activeExcludedVersionFilters,
      facetVersionSearchQuery: fqProductVersion,
      currentSort: "",
    };

    await preSearch();

    fetchAllData(q, { executeSearchInput }).then((response) => {
      setResultCardData(response["resultValuesToRender"]);
      setResultCardAllListings(response["resultsForContentListingItemClicked"]);
      executeSearchHelper(response["onSuccess"], response["naturalCardsData"]);

      dispatch({
        type: "onFilterChange",
        value: {
          rows: 10,
          pageNumber: 1,
          startResultIndex: 0,
          activeVersionFilters: currProductVersionFilter,
          activeExcludedVersionFilters: fqProductVersion,
        },
      });
    });
  };

  const facetSearchContentType = async (
    contentTypeFiltersSelected,
    contentType,
  ) => {
    let updatedActiveVersionFilters = {};
    let updatedActiveExcludedVersionFilters = {};
    const url = new URL(window.location.href);
    url.searchParams.set("p", "1");
    url.searchParams.set("rows", "10");
    const contentTypeFilterParams = contentTypeFiltersSelected.join("&");
    if (contentTypeFilterParams.length) {
      url.searchParams.set(
        "documentKind",
        contentTypeFilterParams.replace(/['"]+/g, ""),
      );
      window.history.pushState({}, "", url.href);
    } else {
      url.searchParams.delete("documentKind");
      window.history.pushState({}, "", url.href);
    }
    if (!contentTypeFiltersSelected.includes('"Documentation"')) {
      url.searchParams.get("documentation_version") &&
        url.searchParams.delete("documentation_version");
      window.history.pushState({}, "", url.href);
      updatedActiveVersionFilters = {};
      updatedActiveExcludedVersionFilters = {};
    }

    const filterUrl = new URLSearchParams(window.location.search);
    let contentTypeFilterFromUrl = filterUrl.get("documentKind") || "";
    let productFilterFromUrl = filterUrl.get("product") || "";
    const productVersionFromUrl = filterUrl.get("documentation_version") || "";
    let filterListType = "";
    if (contentTypeFilterFromUrl.length > 0) {
      contentTypeFilterFromUrl = contentTypeFilterFromUrl
        ?.split("&")
        ?.join("|documentKind~");
      filterListType += `documentKind~${contentTypeFilterFromUrl}`;
    }
    if (productFilterFromUrl.length > 0) {
      productFilterFromUrl = productFilterFromUrl.split("&").join("|product~");
      if (filterListType.length == 0) {
        filterListType += `product~${productFilterFromUrl}`;
      } else {
        filterListType += `|product~${productFilterFromUrl}`;
      }
    }
    if (productVersionFromUrl.length > 0) {
      let formattedVersions = productVersionFromUrl
        ?.split("&")
        .filter((f) => f !== "");
      let versionList = "";
      for (let i = 0; i < formattedVersions.length; i += 2) {
        let currProd = formattedVersions[i];
        let currVersions = formattedVersions[i + 1]?.split(",");
        for (let j = 0; j < currVersions?.length; j++) {
          if (currVersions[j] !== "") {
            versionList += `|documentation-version~${formattedVersions[i]}-${currVersions[j]}`;
          }
        }
      }
      filterListType += versionList;
    }
    if (contentTypeFiltersSelected.includes(`"${contentType}"`)) {
      //@ts-ignore
      window.appEventData = window.appEventData || [];
      //@ts-ignore
      appEventData.push({
        event: "Listing Filter Added",
        listingDisplayed: {
          filterList: filterListType,
        },
        listingRefined: {
          listingType: "content",
        },
      });
    } else {
      //@ts-ignore
      window.appEventData = window.appEventData || [];
      //@ts-ignore
      appEventData.push({
        event: "Listing Filter Removed",
        listingDisplayed: {
          filterList: filterListType,
        },
        listingRefined: {
          listingType: "content",
        },
      });
    }

    let q = url.searchParams.get("q");
    let activeVerions =
      Object.keys(updatedActiveVersionFilters).length == 0
        ? updatedActiveVersionFilters
        : state.activeVersionFilters;
    let activeExcludedVerions =
      Object.keys(updatedActiveExcludedVersionFilters).length == 0
        ? updatedActiveExcludedVersionFilters
        : state.activeExcludedVersionFilters;

    let executeSearchInput = {
      value: q,
      rows: 10,
      pageNumber: 1,
      contentTypes: contentTypeFiltersSelected,
      version: activeVerions,
      sort: state.sort,
      products: state.activeProductFilters,
      listingDriver: "Refinement",
      newSearch: "",
      productVersionFilterQuery: activeExcludedVerions,
      facetVersionSearchQuery: "",
      currentSort: "",
    };

    await preSearch();

    fetchAllData(q, { executeSearchInput }).then((response) => {
      setResultCardData(response["resultValuesToRender"]);
      setResultCardAllListings(response["resultsForContentListingItemClicked"]);
      executeSearchHelper(response["onSuccess"], response["naturalCardsData"]);

      dispatch({
        type: "onFilterChange",
        value: {
          rows: 10,
          pageNumber: 1,
          startResultIndex: 0,
          activeContentTypeFilter: contentTypeFiltersSelected,
          activeVersionFilters: activeVerions,
          activeExcludedVersionFilters: activeExcludedVerions,
        },
      });
    });
  };

  const resultItemClicked = (doc, index) => {
    let currDisplayFeatureTitle;

    if (doc[`[elevated]`] && doc?.documentKind) {
      currDisplayFeatureTitle = `Recommended: ${doc?.documentKind}`;
    } else if (doc?.documentKind === "ModuleDefinition") {
      currDisplayFeatureTitle = `External: console`;
    } else {
      currDisplayFeatureTitle = "";
    }
    //CONTENT LISTING ITEM CLICKED
    //@ts-ignore
    window.appEventData = window.appEventData || [];
    //@ts-ignore
    appEventData.push({
      event: "Content Listing Item Clicked",
      linkInfo: {
        displayPosition: `${index + 1}`,
        category: "Natural",
        text: doc.allTitle ? doc.allTitle : doc.name ? doc.name : doc.title,
        href: doc.view_uri,
        linkTypeName: "search result click",
        targetHost: new URL(doc.view_uri).host,
      },
      listingItemClicked: {
        listingType: "content",
        listing: [
          {
            content: {
              displayFeature: "Natural",
              displayFeatureTitle: currDisplayFeatureTitle,
              contentTitle: `${
                doc.allTitle ? doc.allTitle : doc.name ? doc.name : doc.title
              }`,
              contentUrl: doc?.view_uri,
              contentID: `${doc?.id}`,
              contentPositionRelative: `${doc.position}`, // position relative to display feature
              contentPosition: `${index + 1}`, // Not relatve. across all of search positioning
            },
          },
        ],
      },
    });
  };

  const onSortChange = async (sortString) => {
    let currentSort =
      SolrSortFields.sortFields?.find(
        (sortField) => sortField?.queryParam === sortString,
      )?.select || "Most Relevant";
    //@ts-ignore
    window.appEventData = window.appEventData || [];
    //@ts-ignore
    appEventData.push({
      event: "Listing Sort Order Changed",
      listingRefined: {
        listingType: "content",
        sortOrder: currentSort,
      },
    });
    const url = new URL(window.location.href);
    url.searchParams.set("p", "1");
    url.searchParams.set("rows", "10");
    url.searchParams.set("sort", sortString);
    window.history.pushState({}, "", url.href);

    let sortParam = sortString == "relevant" ? "" : sortString;

    let q = url.searchParams.get("q");

    let executeSearchInput = {
      value: q,
      rows: 10,
      pageNumber: 1,
      contentTypes: state.activeContentTypeFilter,
      version: state.activeVersionFilters,
      sort: sortParam,
      products: state.activeProductFilters,
      listingDriver: "Refinement",
      newSearch: "",
      productVersionFilterQuery: {},
      facetVersionSearchQuery: {},
      currentSort: currentSort,
    };

    await preSearch();

    fetchAllData(q, { executeSearchInput }).then((response) => {
      setResultCardData(response["resultValuesToRender"]);
      setResultCardAllListings(response["resultsForContentListingItemClicked"]);
      executeSearchHelper(response["onSuccess"], response["naturalCardsData"]);

      dispatch({
        type: "onSortChange",
        value: {
          sort: sortParam,
          rows: 10,
          pageNumber: 1,
          startResultIndex: 0,
        },
      });
    });
  };

  const clearAllFilters = async () => {
    const url = new URL(window.location.href);
    url.searchParams.get("documentKind") &&
      url.searchParams.delete("documentKind");
    url.searchParams.get("product") && url.searchParams.delete("product");
    url.searchParams.get("documentation_version") &&
      url.searchParams.delete("documentation_version");
    window.history.pushState({}, "", url.href);

    let q = url.searchParams.get("q");

    let executeSearchInput = {
      value: q,
      rows: 10,
      pageNumber: 1,
      contentTypes: "",
      version: "",
      sort: state.sort,
      products: "",
      listingDriver: "",
      newSearch: "",
      productVersionFilterQuery: {},
      facetVersionSearchQuery: {},
      currentSort: "",
    };

    await preSearch();

    fetchAllData(q, { executeSearchInput }).then((response) => {
      setResultCardData(response["resultValuesToRender"]);
      setResultCardAllListings(response["resultsForContentListingItemClicked"]);
      executeSearchHelper(response["onSuccess"], response["naturalCardsData"]);

      dispatch({
        type: "onFilterChange",
        value: {
          rows: 10,
          pageNumber: 1,
          startResultIndex: 0,
          activeContentTypeFilter: [],
          activeProductFilters: [],
        },
      });
    });
  };

  const onToggleMobileFilter = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      <div
        className={` ${state.numResults > 0 ? `app-wrapper-onebox-test` : `app-wrapper`} ${state.numResults === 0 && !activeFilters ? `sidebar-hidden` : ""} ${state.loading &&  `app-wrapper-loading`}`}>
        <div
          className={` ${state.numResults > 0 ? `input-wrapper-onebox-test` : `input-wrapper`} ${
            state.numResults === 0 && !activeFilters
              ? `input-bar-empty-state`
              : ""
          }`}>
          {
            <div className="search__input-pagination-wrapper" id="top-wrapper">
              <div
                className={`search__input-form-wrapper ${
                  state.numResults > 0
                    ? "search__input-form-wrapper-with-result"
                    : ""
                } ${
                  state.loading ? "search__input-form-wrapper-loading" : ""
                }`}>
                <input-form
                  data-testid="inputForm"
                  ref={inputFormRef}
                  query={state.inputValue}
                  q={state.inputValue}
                  disabled={state.loading}
                  required
                  pattern=".*[a-zA-Z0-9]+.*"></input-form>
              </div>
            </div>
          }

          {/* suggestion state */}
          {state.results.length === 0 &&
            state.numResults === 0 &&
            !state.loading &&
            !state.error &&
            !activeFilters &&
            state.inputValue !== "" &&
            state.init !== true && (
              <Suggestions
                suggestionStateData={suggestionStateData}
                topPagesData={topPagesData}
                trendingSearchesData={trendingSearchesData}
              />
            )}

          {state.results.length === 0 && state.error && !state.loading && (
            <ErrorComponent topPagesData={topPagesData} />
          )}

          {/* trending searches for empty state */}
          {state.results.length === 0 &&
            !state.error &&
            !state.loading &&
            !state.suggestions.length &&
            state.inputValue === "" && (
              <EmptyStateSuggestions
                topPagesData={topPagesData}
                trendingSearchesData={trendingSearchesData}
              />
            )}
        </div>{" "}
        {/* what is this closing? */}
        <div
          className={` ${
             state.numResults > 0 ? `sidebar-onebox-test` : `sidebar`
          } ${
            state.numResults == 0 &&
            state.activeContentTypeFilter.length == 0 &&
            state.activeProductFilters.length == 0
              ? `sidebar-no-results`
              : ""
          }`}>
          {/* {state.numResults > 0 && !state.error &&  */}
          {!state.error && (state.numResults > 0 || activeFilters) && (
            <div
              className={` ${
                state.numResults > 0 && `filters-container`
              }`}>
              <div className="filter-heading-wrapper">
                <div className="filter-text-container">
                  <p
                    data-testid="filter-by-state"
                    className="filter-text"
                    aria-hidden="true">
                    FILTER BY{" "}
                    {state.activeProductFilters.length +
                      state.activeContentTypeFilter.length >
                    0
                      ? `(${
                          state.activeProductFilters.length +
                          state.activeContentTypeFilter.length
                        })`
                      : ""}
                  </p>
                  <h2 className="visually-hidden">
                    "Filter search results by Products or Content Type
                  </h2>
                </div>
              </div>
              <div className="clear-filter-container">
                {(state.activeProductFilters.length > 0 ||
                  state.activeContentTypeFilter.length > 0) && (
                  <Button
                    variant="secondary"
                    isBlock
                    className="clear-all-buttom"
                    onClick={() => clearAllFilters()}>
                    Clear all
                  </Button>
                )}
                <Filters
                  productsSelectedItemsArray={
                    state.activeProductFilters.length
                      ? state.activeProductFilters
                      : []
                  }
                  contentTypesSelectedItemsArray={
                    state.activeContentTypeFilter.length
                      ? state.activeContentTypeFilter
                      : []
                  }
                  productOptions={state.productFilters}
                  contentTypeOptions={state.documentKindFilters}
                  onProductUpdate={(selectedOptions, product) => {
                    facetSearch(selectedOptions, product);
                  }}
                  onContentTypeUpdate={(selectedOptions, contentType) => {
                    facetSearchContentType(selectedOptions, contentType);
                  }}
                  onUpdateVersions={(
                    selectedOptions,
                    product,
                    allVersions,
                    selection,
                  ) => {
                    facetVersionSearch(
                      selectedOptions,
                      product,
                      allVersions,
                      selection,
                    );
                  }}
                  activeProductVersions={state.activeVersionFilters}
                  activeContentTypeFilter={state.activeContentTypeFilter}
                  productVersions={state.documentVersionFilters}
                  productKeyProp={`product-filter-checklist`}
                  contentTypeKeyProp={`content-type-filter-checklist`}
                  isDisabled={state.loading}
                  isMobile={false}
                />
              </div>
            </div>
          )}
        </div>
        <div
          className={`${
            state.numResults > 0
              ? `results-wrapper-onebox-test` : `results-wrapper`
          } ${
            state.numResults == 0 && !activeFilters
              ? `results-wrapper-hidden`
              : ""
          }`}>
          {state.loading && <LoadingState rows={state.rows} />}
          {(state.numResults > 0 || activeFilters) &&
            !state.loading &&
            !state.error && (
              <div className="total-results-container">
                <div className="total-results-sort-by-container">
                  <div
                    className="num-results-container"
                    data-testid="total-results">
                    {state.numResults > 0 && (
                      <>
                        <p className="total-results-heading-text">
                          Search Results
                        </p>
                        <p className="num-results-text">
                          {state.numResults < state.rows
                            ? Intl.NumberFormat().format(state.numResults)
                            : state.rows}{" "}
                          {state.numResults > 10 &&
                            `of ${Intl.NumberFormat().format(
                              state.numResults,
                            )}`}
                        </p>
                      </>
                    )}
                    {activeFilters && state.numResults === 0 && (
                      <p className="no-results-with-filter-text">
                        No results found for:{" "}
                        <span className="no-results-with-filter-text-query">
                          {state.inputValue}
                        </span>
                      </p>
                    )}
                  </div>
                  {state.suggestions.length > 0 &&
                    state.results.length > 0 &&
                    state.results.length < 10 && (
                      <div className="few-results-wrapper-mobile">
                        <p className="few-results-text">Did you mean: </p>
                        {state?.suggestions.map((suggestion, i) => [
                          i > 0 && ", ",
                          <Link
                            className="few-results-text-link"
                            to={`?q=${suggestion}`}
                            key={i}>
                            {suggestion}
                          </Link>,
                        ])}
                      </div>
                    )}
                  {state.numResults > 0 && (
                    <div className="dropdown-sort-container">
                      <p className="dropdown-sort-text" aria-hidden="true">
                        Sort by:
                      </p>
                      <span className="visually-hidden">
                        Sort by {CURRENT_SORT}
                      </span>
                      <DropdownSort
                        sortFields={SolrSortFields.sortFields}
                        isPlain
                        showSort={true}
                        onSortChange={onSortChange}
                        activeSort={state.sort}
                      />
                    </div>
                  )}
                </div>
                {state.suggestions.length > 0 &&
                  state.results.length > 0 &&
                  state.results.length < 10 && (
                    <div className="few-results-wrapper-desktop">
                      <p className="few-results-text">Did you mean: </p>
                      {state?.suggestions.map((suggestion, i) => [
                        i > 0 && ", ",
                        <Link
                          className="few-results-text-link"
                          to={`?q=${suggestion}`}>
                          {suggestion}
                        </Link>,
                      ])}
                    </div>
                  )}

                {(state.numResults > 0 || activeFilters) && (
                  <div className="filters-container-mobile">
                    <ExpandableSection
                      toggleText={"FILTER BY"}
                      onToggle={onToggleMobileFilter}
                      isExpanded={isExpanded}>
                      <div className="filters-container">
                        <div className="filter-heading-wrapper">
                          {(state.activeProductFilters.length > 0 ||
                            state.activeContentTypeFilter.length > 0) && (
                            <div className="clear-filter-container">
                              <Button
                                variant="secondary"
                                isBlock
                                className="clear-all-buttom"
                                onClick={() => clearAllFilters()}>
                                Clear all
                              </Button>
                            </div>
                          )}
                          <Filters
                            productsSelectedItemsArray={
                              state.activeProductFilters.length
                                ? state.activeProductFilters
                                : []
                            }
                            contentTypesSelectedItemsArray={
                              state.activeContentTypeFilter.length
                                ? state.activeContentTypeFilter
                                : []
                            }
                            productOptions={state.productFilters}
                            contentTypeOptions={state.documentKindFilters}
                            onProductUpdate={(selectedOptions, product) => {
                              facetSearch(selectedOptions, product);
                            }}
                            onContentTypeUpdate={(
                              selectedOptions,
                              contentType,
                            ) => {
                              facetSearchContentType(
                                selectedOptions,
                                contentType,
                              );
                            }}
                            onUpdateVersions={(
                              selectedOptions,
                              product,
                              allVersions,
                              selection,
                            ) => {
                              facetVersionSearch(
                                selectedOptions,
                                product,
                                allVersions,
                                selection,
                              );
                            }}
                            activeProductVersions={state.activeVersionFilters}
                            activeContentTypeFilter={
                              state.activeContentTypeFilter
                            }
                            productVersions={state.documentVersionFilters}
                            productKeyProp={`product-filter-checklist`}
                            contentTypeKeyProp={`content-type-filter-checklist`}
                            isDisabled={state.loading}
                            isMobile={true}
                          />
                        </div>
                      </div>
                    </ExpandableSection>
                  </div>
                )}
              </div>
            )}
          {state.numResults > 0 && !state.loading && (
            <div className={"onebox-ab-test-mobile"}>
              {oneBoxTestResultCard}
            </div>
          )}
          {((state.results && state.results.length > 0) || activeFilters) &&
            !state.error &&
            !state.loading && (
              <ResultCards
                resultCardData={resultCardData}
                resultCardAllListings={resultCardAllListings}
                inputValue={state.inputValue}
                resultItemClicked={resultItemClicked}
                isAuthenticated={isAuthenticated}
                isProductABTest={isProductABTest}
                productABTestVariant={productABTestVariant}
              />
            )}
          {state.results && state.results.length > 0 && !state.error && (
            <div className="search__footer-wrapper">
              <Pagination
                itemCount={state.numResults >= 9999 ? 9999 : state.numResults}
                widgetId="pagination-options-menu-bottom"
                perPage={state.rows}
                page={state.pageNumber}
                onSetPage={(_event, newPage) =>
                  onPaginationChange(state.rows, newPage)
                }
                onPerPageSelect={(_event, newPerPage) =>
                  onPaginationChange(newPerPage, state.pageNumber)
                }
                isDisabled={state.numResults !== 0 ? false : true}
                isCompact={false}
                itemsStart={state.startResultIndex + 1}
                variant={"bottom"}
              />
            </div>
          )}
        </div>
        {state.numResults > 0 && !state.loading && (
          <div className={"onebox-ab-test-desktop"}>{oneBoxTestResultCard}</div>
        )}
      </div>
    </div>
  );
}

export default App;
