import { getSession, fetchLoggedInData } from "./auth";
import { versionCompare } from "./Utils";

let rules;
let lastOneBoxValue;
let oneBoxData;
let enrollmentInOneboxTest;

let lastTroubleshootValue;
let troubleShootData;

let lastRelatedSearchesValue;
let relatedSearchesData;

const findRuleMatch = (value, rules, exactMatch) => {
  let hasRule = false;
  let currRuleInfo = null;
  for (let i = 0; i < rules?.length; i++) {
    let rule = rules[i];
    let length = rule.keywords?.length;

    for (let j = 0; j < length; j++) {
      if (exactMatch) {
        if (value.toLowerCase() === rule.keywords[j].toLowerCase()) {
          hasRule = true;
          currRuleInfo = rule;
        }
      } else {
        // Add 'escape hatch' for the 'eap' substring
        if (value.indexOf(rule.keywords[j]) > -1 && !value.includes("leapp")) {
          hasRule = true;
          currRuleInfo = rule;
        }
      }

      if (hasRule) {
        break;
      }
    }
  }
  return currRuleInfo;
};

//define onebox icons used for onebox in a/b test
const oneboxIcons = [
  { Overview: { icon: "binoculars", iconSet: "fas" } },
  { Downloads: { icon: "download", iconSet: "fas" } },
  { Documentation: { icon: "book", iconSet: "fas" } },
  { Training: { icon: "book-reader", iconSet: "fas" } },
  { Try: { icon: "bicycle", iconSet: "fas" } },
  { Labs: { icon: "flask", iconSet: "fas" } },
  { Errata: { icon: "port", iconSet: "patternfly" } },
  { Ansible: { icon: "ansible-tower", iconSet: "patternfly" } },
  { Solution: { icon: "clipboard-check", iconSet: "fas" } },
  { Vulnerability: { icon: "critical-risk", iconSet: "patternfly" } },
  { Video: { icon: "play-circle", iconSet: "far" } },
  { Discussions: { icon: "comments", iconSet: "far" } },
  { Help: { icon: "question-circle", iconSet: "far" } },
];

export async function fetchOneBox(value, host) {
  const sameLastValue = lastOneBoxValue ? lastOneBoxValue === value : false;

  // if we get the same value as the last time fetchOneBox was called
  // and we already have oneBoxData, just return the oneBoxData instead
  // of making the API call and/or doing the matching logic
  if (sameLastValue && oneBoxData) {
    return oneBoxData;
  }

  try {
    if (!rules) {
      const response = await fetch(
        `https://${host}/api/v1/dxp-content/onebox/access-redhat-com`,
      );
      const json = await response.json();
      rules = json.data.oneboxes;
    }

    // try to find an exact match first with a strict equals check
    // if we don't find one, try to locate the value in one of the rules keywords array using indexOf
    let ruleMatchInfo =
      findRuleMatch(value, rules, true) ?? findRuleMatch(value, rules, false);

    oneBoxData = {};

    if (!ruleMatchInfo) {
      oneBoxData.show = false;
      oneBoxData.data = [];
    } else {
      let currData = [
        {
          title: ruleMatchInfo?.heading,
          description: ruleMatchInfo?.description,
          links: ruleMatchInfo?.links,
        },
      ];

      oneBoxData.show = true;
      oneBoxData.data = currData;
      oneBoxData.displayFeature = "OneBox";
      oneBoxData.listings = ruleMatchInfo?.links?.map((link, index) => {
        return {
          displayFeature: "OneBox",
          displayFeatureTitle: `OneBox: ${ruleMatchInfo?.heading}`,
          contentTitle: link?.linkText,
          contentUrl: link?.link,
          contentPositionRelative: `${index + 1}`,
          contentID: link?.link,
        };
      });
    }

    lastOneBoxValue = value;
    return oneBoxData;
  } catch (error) {
    console.log("one-box error", error);
  }
}

export async function executeTroubleShoot(value, host, confidence_threshold) {
  const sameLastValue = lastTroubleshootValue
    ? lastTroubleshootValue === value
    : false;

  // if we get the same value as the last time executeTroubleShoot was called
  // and we already have troubleShootData, just return the troubleShootData instead
  // of making the API call
  if (sameLastValue && troubleShootData) {
    return troubleShootData;
  }

  try {
    const response = await fetch(
      `https://${host}/hydra/rest/search/introspect?query=${value}`,
    );
    const data = await response.json();

    troubleShootData = {};
    let troubleshootCardData = [{ title: "People also searched for" }];

    if (
      data.intent === "TROUBLESHOOT" &&
      data.confidence_score >= confidence_threshold
    ) {
      troubleShootData.show = true;
      troubleShootData.displayFeature = "Troubleshoot";
      troubleShootData.data = troubleshootCardData;
      troubleShootData.listings = [
        {
          displayFeature: "Troubleshoot",
          displayFeatureTitle: "Troubleshoot your issue",
          contentTitle: "Troubleshoot your issue",
          contentUrl: "https://access.redhat.com/support/cases/#/troubleshoot",
          contentID: "https://access.redhat.com/support/cases/#/troubleshoot",
          contentPositionRelative: "1",
        },
      ];
    } else {
      troubleShootData.show = false;
    }

    lastTroubleshootValue = value;
    return troubleShootData;
  } catch (error) {
    console.log("intent error");
    console.log("error", error);
  }
}

export async function fetchRelatedSearches(value, host) {
  const sameLastValue = lastRelatedSearchesValue
    ? lastRelatedSearchesValue === value
    : false;

  // if we get the same value as the last time fetchRelatedSearches was called
  // and we already have relatedSearchesData, just return the relatedSearchesData instead
  // of making the API call
  if (sameLastValue && relatedSearchesData) {
    return relatedSearchesData;
  }

  //fetch related search if query param is single word -- no whitespace
  relatedSearchesData = {};

  if ((value.trim().indexOf(" ") === -1) === false) {
    relatedSearchesData.show = false;
    return relatedSearchesData;
  } else {
    try {
      const response = await fetch(
        `https://${host}/hydra/rest/search/suggestions/related-searches?fq=refined_suggestion_word_count:[2 TO 3]&q=${value}&redhat_client=portal-search&rows=3`,
      );
      const data = await response.json();
      const related = data?.response?.docs.map((d) => {
        return {
          text: d.refined_suggestion,
          link: `?q=${d.refined_suggestion}`,
        };
      });

      lastRelatedSearchesValue = value;

      if (related.length === 0) {
        relatedSearchesData.show = false;
        return relatedSearchesData;
      } else {
        relatedSearchesData = [
          { title: "People also searched for", ...related },
        ];
        relatedSearchesData.show = true;
        relatedSearchesData.data = relatedSearchesData;
        relatedSearchesData.displayFeature = "People Also Searched";
        relatedSearchesData.listings = related?.map((relatedSearch, index) => {
          return {
            displayFeature: "People Also Searched",
            displayFeatureTitle: "People also search for",
            contentTitle: relatedSearch?.text,
            contentUrl: relatedSearch?.link,
            contentPositionRelative: `${index + 1}`,
            contentID: relatedSearch?.link,
          };
        });

        return relatedSearchesData;
      }
    } catch (error) {
      console.log("related searches error");
      console.log("error", error);
    }
  }
}

export async function getTopDocuments(query, host, results) {
  let topDocData = [];
  const session = await getSession();
  try {
    session.then(async (userInfo) => {
      if (!userInfo) {
        return topDocData;
      } else {
        const data = await fetchLoggedInData(
          `https://${host}/hydra/rest/search/top-documents/users/${userInfo.user_id}?q=${query}`,
          userInfo.token,
        );
        return { topDocuments: data };
      }
    });
  } catch (error) {
    return topDocData;
  }
}
export async function executeSearch(
  value,
  HOST,
  rows,
  pageNumber,
  contentTypes = "",
  version = "",
  sort = "",
  products = "",
  listingDriver = "",
  newSearch = "",
  productVersionFilterQuery,
  facetVersionSearchQuery = {},
  currentSort,
) {
  let data = {};
  const url = new URLSearchParams(window.location.search);
  const navSearch = url.get("search-type") === "global" ? true : false;

  if (newSearch == "newSearch" || navSearch) {
    //ONSITE SEARCH PERFORMED
    //@ts-ignore
    window.appEventData = window.appEventData || [];
    //@ts-ignore
    window.appEventData.push({
      event: "Onsite Search Performed",
      onsiteSearch: {
        keyword: {
          searchType: navSearch ? "global_search" : "search_page",
          searchTerm: value,
          searchMethod: "manual",
        },
      },
    });
    const url = new URL(window.location.href);
    url.searchParams.get("search-type") &&
      url.searchParams.delete("search-type");
    window.history.replaceState({}, "", url.href);
  }

  //encode query param
  value = encodeURIComponent(value);
  // SOLR does not support a start value above 9999
  let startResultIndex =
    (pageNumber - 1) * rows > 9999 ? 9999 - rows : (pageNumber - 1) * rows;
  // if page number is 1000 or more ...
  if (startResultIndex + rows > 9999) {
    startResultIndex = startResultIndex - 1;
  }

  try {
    // filter for a documentKind
    // create a tag in the filter query (fq)
    // exclude that tag in facet.filter
    // facet.field=true&fq={!tag=documentKindFilter}documentKind("Course or whatever")&facet.filter={!ex=documentKindFilter}documentKind
    const contentTypeFacetField = `facet.field={!ex=documentKindFilter}documentKind`;

    //@ts-ignore
    const urlContentTypes =
      contentTypes.length > 1 ? contentTypes.join(" OR ") : contentTypes;
    const facetQueryContentType = urlContentTypes.length
      ? `&fq={!tag=documentKindFilter}documentKind:(${urlContentTypes})`
      : "";

    const highlight = `&hl=true&hl.fl=abstract, publishedAbstract, snippet&hl.simple.pre=<mark>&hl.simple.post=<%2Fmark>&hl.fragsize=256`;

    const sortQuery = sort.length && sort !== "relevant" ? `&sort=${sort}` : "";
    const productFacetField = `&facet.field={!ex=productFilter}standard_product`;

    //@ts-ignore
    const urlProducts =
      products.length > 1 ? products.join(" OR standard_product:") : products;

    const fqProduct = urlProducts.length
      ? `&fq={!tag=productFilter}standard_product:${urlProducts}`
      : "";

    let fQVersionsMerged = {
      ...productVersionFilterQuery,
      ...facetVersionSearchQuery,
    };

    //filter out prodcuts with no versions selected - empty strings
    let updatedFqArr = [];
    if (products.length > 0) {
      //@ts-ignore
      let productsWithoutQuotes = products?.map((p) => p.replaceAll('"', ""));
      for (const product in fQVersionsMerged) {
        //if there are versions associated with a product and the product associated with these versions are present in our active products filter
        //we want to push the fq documentation_version string to our updatedFqArr array which will contain all fq's for documentation_version
        if (
          fQVersionsMerged[product] !== "" &&
          productsWithoutQuotes.includes(product)
        ) {
          //@ts-ignore
          updatedFqArr.push(fQVersionsMerged[product]);
        }
      }
    }

    let fqProductVersion = "";

    //if no products are selected, fqProductVersion becomes an empty string because we should not be querying on any product versions
    if (products.length == 0) {
      fqProductVersion = "";
    }

    //if fq's for documentation_version are present, add them together so we can apply them to our executeSearch fetch call
    if (updatedFqArr.length > 0) {
      updatedFqArr?.forEach((fq) => {
        fqProductVersion += `&fq={!tag=productFilter}-(${fq})`;
      });
    }
    //TODO: I don't think I need to add this to API call? Find out...
    const versionFacetField = `&facet.field={!ex=documentVersionFilter}documentation_version`;
    //only pivot on product and documentation_version if a product filter is active
    const facetPivotString =
      products?.length > 0
        ? `&facet.pivot={!ex=productFilter, documentVersionFilter}standard_product,documentation_version&f.documentation_version.facet.sort=index`
        : "";

    const flFields =
      "id,view_uri,allTitle,publishedTitle,requires_subscription,documentKind,documentation_version,standard_product,lastModifiedDate,uri,url,abstract,publishedAbstract,ModerationState,source,snippet";

    let excludedDocAndId =
      "&fq=-documentKind:(PortalProduct OR ContainerVendor OR Packages)&fq=-id:Other";

    //@ts-ignore
    let lang =
      window?.portal?.lang == "en" ? "en" : `"en" OR "${window?.portal?.lang}"`;
    let language = `&fq=language:(${lang})`;

    let fetchUrl = `https://${HOST}`;

    let response;
    const defaultEndpoint = `${fetchUrl}/hydra/rest/search/kcs?q=${value}&start=${startResultIndex}&rows=${rows}${language}${sortQuery}${facetPivotString}&facet=true&facet.mincount=1&fl=${flFields}&enableElevation=true&f.standard_product.facet.limit=-1&redhat_client=portal-search&facet.pivot.mincount=1&${contentTypeFacetField}${facetQueryContentType}${productFacetField}${fqProduct}${fqProductVersion}${highlight}${excludedDocAndId}`;

    response = await fetch(defaultEndpoint);
    data = await response.json();

    let docResults = data?.response?.docs;

    let suggestionsArr = data?.spellcheck?.collations;
    //remove first element which is "collation"
    suggestionsArr.shift();

    //populate facets for content type
    let allContentTypes = data.facet_counts?.facet_fields.documentKind;
    let allProducts = data.facet_counts?.facet_fields?.standard_product;
    const facetsForProducts = [];
    const facetsForContentTypes = [];
    let contentTypesToDisplay = [
      "Documentation",
      "Solution",
      "Article",
      "Discussion",
      "Product",
      "Video",
      "Blog",
      "LabInfo",
      "LearningPathResource",
      "LearningPath"
    ];
    for (var i = 0; i < allContentTypes.length; i += 2) {
      if (contentTypesToDisplay.includes(allContentTypes[i])) {
        facetsForContentTypes.push({
          heading: allContentTypes[i],
          solrKey: allContentTypes[i],
          numResults: allContentTypes[i + 1],
        });
      }
    }

    //populate facets for products
    for (var i = 0; i < allProducts.length; i += 2) {
      facetsForProducts.push({
        heading: allProducts[i],
        solrKey: allProducts[i],
        numResults: allProducts[i + 1],
      });
    }

    //populate facets for documentVersions
    let documentVersions =
      data?.facet_counts?.facet_pivot?.[
        "standard_product,documentation_version"
      ];
    const facetsForDocumentVersions = [];

    if (documentVersions?.length > 0) {
      for (var i = 0; i < documentVersions?.length; i += 1) {
        let product = documentVersions[i]?.value;
        let versions = [];

        for (let j = 0; j < documentVersions[i]?.pivot?.length; j++) {
          //adding versionCount to versions arr for facet enumeration A/B test
          let versionNumber = documentVersions[i].pivot[j].value;
          let versionCount = documentVersions[i].pivot[j].count;

          //@ts-ignore
          versions.push([versionNumber, versionCount]);
        }
        facetsForDocumentVersions.push({
          [product]: versions.sort((a, b) => versionCompare(a[0], b[0])),
        });
      }
    }

    //map highlighted text to doc abstarct
    let highlightedTextForUi = [];
    for (const doc in docResults) {
      let docId = docResults[doc].uri;
      let highlighted_text =
        data?.highlighting[`${docId}`]?.snippet ||
        data?.highlighting[`${docId}`]?.publishedAbstract ||
        data?.highlighting[`${docId}`]?.abstract;
      // @ts-ignore
      highlightedTextForUi.push(highlighted_text);
    }

    let index = 0;
    docResults?.map((result) => {
      result.highlightedText = highlightedTextForUi[index++];
    });

    const filterUrl = new URLSearchParams(window.location.search);
    let contentTypeFilterFromUrl = filterUrl.get("documentKind") || "";
    let productFilterFromUrl = filterUrl.get("standard_product") || "";
    const productVersionFromUrl = filterUrl.get("documentation_version") || "";
    let filterListType = "";
    if (contentTypeFilterFromUrl.length > 0) {
      contentTypeFilterFromUrl = contentTypeFilterFromUrl
        ?.split("&")
        ?.join("|documentKind~");
      filterListType += `documentKind~${contentTypeFilterFromUrl}`;
    }
    if (productFilterFromUrl.length > 0) {
      productFilterFromUrl = productFilterFromUrl.split("&").join("|product~");
      if (filterListType.length == 0) {
        filterListType += `product~${productFilterFromUrl}`;
      } else {
        filterListType += `|product~${productFilterFromUrl}`;
      }
    }
    if (productVersionFromUrl.length > 0) {
      let formattedVersions = productVersionFromUrl
        ?.split("&")
        .filter((f) => f !== "");
      let versionList = "";
      for (let i = 0; i < formattedVersions.length; i += 2) {
        let currProd = formattedVersions[i];
        let currVersions = formattedVersions[i + 1]?.split(",");
        for (let j = 0; j < currVersions?.length; j++) {
          if (currVersions[j] !== "") {
            versionList += `|documentation-version~${formattedVersions[i]}-${currVersions[j]}`;
          }
        }
      }
      filterListType += versionList;
    }

    data.onSuccess = true;
    data.facetInfo = data?.facet_counts;
    data.show = true;
    data.displayFeature = "Natural";
    data.data = docResults;
    data.suggestionsArr = suggestionsArr;
    data.docResults = docResults;
    data.facetsForContentTypes = facetsForContentTypes;
    data.facetsForProducts = facetsForProducts;
    data.facetsForDocumentVersions = facetsForDocumentVersions;
    data.listings = docResults?.map((doc, index) => {
      let currDisplayFeatureTitle;

      if (doc[`[elevated]`] && doc?.documentKind) {
        currDisplayFeatureTitle = `Recommended: ${doc?.documentKind}`;
      } else if (doc?.documentKind === "ModuleDefinition") {
        currDisplayFeatureTitle = `External: console`;
      } else {
        currDisplayFeatureTitle = "";
      }
      return {
        displayFeature: "Natural",
        displayFeatureTitle: currDisplayFeatureTitle,
        contentTitle: doc?.publishedTitle || doc?.allTitle,
        contentUrl: doc?.view_uri,
        contentPositionRelative: `${index + 1}`,
        contentID: `${doc?.id}`,
      };
    });
    return data;
  } catch (error) {
    console.log("error", error);
    data.onError = true;
    data.show = false;

    return data;
  }
}
